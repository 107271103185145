<template>
  <div
    class="py-25 navbar-container d-flex justify-content-between content align-items-center px-0 flexbox-shrink-fix"
  >
    <div class="navbar-nav flex-grow-1 d-flex flexbox-shrink-fix flex-nowrap">
      <!-- Toggle Vertical menu -->
      <b-link
        v-if="hasMenu"
        class="nav-link menu d-flex align-items-center pl-0"
        @click="toggleVerticalMenuActive"
      >
        <feather-icon icon="MenuIcon" size="21" />
      </b-link>

      <!-- Nexos Logo -->
      <template>
        <img 
          src="/assets/customers/nexos/logo.jpg"
          alt="Nexos Logo"
          height="56"
          class="ml-1 logo-nexos"
          @click="goToMainRoute"
          style="cursor: pointer"
        />
      </template>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto settings justify-content-end">

      <!-- ############## Notifications button ############## -->
      <NotificationsLink
        v-if="isCommunity && !hasNotMenu && loggedMember"
        :notifications-count="unreadNotificationsCount"
        :notifications-list="notificationsList"
        class="d-none d-lg-block p-0 mr-1"
      />

      <!-- ############## USER / MEMBER dropdown menu ############## -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!-- Desktop -->
          <UserAvatar
            :user="userOrMember"
            size="40"
            variant="light-primary"
            class="d-none d-lg-inline-flex badge-minimal user-avatar-border"
            badge-variant="warning"
            :badge="isFirstConnection"
          />
          <!-- Mobile -->
          <UserAvatar
            :user="userOrMember"
            size="40"
            variant="light-primary"
            :badge-variant="isFirstConnection ? 'warning' : 'danger'"
            class="badge-minimal d-lg-none user-avatar-border"
            :badge="unreadNotificationsCount > 0 || isFirstConnection"
          />
        </template>

        <!-- Profile -->
        <b-dropdown-text class="bg-light">
          <b-link
            :disabled="hasNotMenu || !loggedMember || !isViewerMemberOrStaff"
            :href="!hasNotMenu ? myProfileLocation() : ''"
            class="text-dark"
          >
            <p class="user-name font-weight-bolder mb-0">
              {{ profileFullName }}
              <span class="font-weight-normal">
                {{ profileEmail }}
              </span>
            </p>
          </b-link>
        </b-dropdown-text>

        <template v-if="isCommunity">
          <!-- My Account -->
          <b-dropdown-item 
            v-if="!hasNotMenu && loggedMember" 
            class="mb-0" 
            :to="memberAccountRoute"
          >
            <span class="d-flex align-items-center">
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>{{ $t("user-menu.profile-settings") }}</span>
            </span>
            <span v-if="isFirstConnection" class="d-flex align-items-center text-warning">
              <feather-icon icon="AlertCircleIcon" class="mr-25" />
              <span>{{ $t("missing-information") }}</span>
            </span>
          </b-dropdown-item>

          <!-- Main Space Section -->
          <template v-if="hasMainCommunity">
            <!-- Main Space Info -->
            <b-dropdown-text class="bg-light">
              <p class="user-name font-weight-bolder mb-0 mr-25">
                {{ translate(mainCommunity.name) }}
              </p>
              <!-- Staff / Member -->
              <span
                v-if="mainCommunity.accessibility !== 3"
                class="d-flex align-items-center"
              >
                <span
                  v-if="mainCollective.isStaff"
                  class="d-flex align-items-center"
                >
                  <b-icon-shield-check
                    v-b-tooltip.hover.bottom
                    size="1"
                    :title="$t('user-menu.staff-tooltip')"
                  />
                  <p class="user-name mb-0 mr-25 ml-25">
                    {{ $t("user-menu.staff") }}
                  </p>
                </span>
                <span
                  v-if="mainCollective.isStaff && isMainCollectiveMember"
                  class="mx-25"
                >
                  {{ $t("user-menu.&") }}
                </span>
                <span
                  v-if="isMainCollectiveMember"
                  class="d-flex align-items-center"
                >
                  <b-icon-person
                    v-b-tooltip.hover.bottom
                    size="1"
                    :title="$t('user-menu.member-tooltip')"
                  />
                  <p class="user-name mb-0 mr-25 ml-25">
                    {{ $t("user-menu.member") }}
                  </p>
                </span>
              </span>
            </b-dropdown-text>

            <!-- Main Backoffice -->
            <b-dropdown-item
              v-if="mainCollective.isStaff"
              link-class="d-flex align-items-center"
              target="_blank"
              :href="backofficeURL(mainCommunity, true)"
            >
              <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
              <span>{{ $t("user-menu.main-backoffice") }} </span>
            </b-dropdown-item>
          </template>

          <!-- Current Space Section -->
          <b-dropdown-text class="bg-light">
            <p class="user-name font-weight-bolder mb-0 sub-name">
              {{ translate(currentCollective.name) }}
            </p>
            <!-- Staff / Member -->
            <span
              v-if="currentCollective.accessibility !== 3"
              class="d-flex align-items-center"
            >
              <!-- Staff -->
              <span v-if="isStaffOfThis" class="d-flex align-items-center">
                <b-icon-shield-check
                  v-b-tooltip.hover.bottom
                  size="1"
                  :title="$t('user-menu.staff-tooltip')"
                />
                <p class="user-name mb-0 mr-25 ml-25">
                  {{ $t("user-menu.staff") }}
                </p>
              </span>
              <!-- & -->
              <span
              v-if="isStaffOfThis && isCommunityMember" class="mx-25">
                {{ $t("user-menu.&") }}
              </span>
              <!-- Member -->
              <span
                v-if="isCommunityMember" class="d-flex align-items-center">
                <b-icon-person
                  v-b-tooltip.hover.bottom
                  size="1"
                  :title="$t('user-menu.member-tooltip')"
                />
                <p class="user-name mb-0 mr-25 ml-25">
                  {{ $t("user-menu.member") }}
                </p>
              </span>
            </span>
          </b-dropdown-text>

          <!-- Edit layout -->
          <b-dropdown-item
            v-if="canEditLayout && !isEditingLayout"
            link-class="d-flex align-items-center"
            target="_blank"
            @click="handleEditLayout"
          >
            <div v-b-tooltip.hover :title="$t('tooltips.edit-layout')">
              <feather-icon size="16" icon="Edit2Icon" class="mr-50" />
              <span>{{ $t("header.action.edit-layout") }}</span>
            </div>
          </b-dropdown-item>

          <!-- Backoffice -->
          <b-dropdown-item
            v-if="isStaff"
            target="_blank"
            link-class="d-flex align-items-center"
            :href="backofficeURL(mainCommunity)"
          >
            <div v-b-tooltip.hover :title="$t('tooltips.backoffice')">
              <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
              <span>Back-Office</span>
            </div>
          </b-dropdown-item>
          <!-- Welcome -->
          <b-dropdown-item
            v-if="$route.name !== 'welcome'"
            link-class="d-flex align-items-center"
            :href="getCollectiveUrl(collective)"
          >
            <b-icon-door-open class="mr-50" />
            <span>{{ $t('header.action.welcome') }}</span>
          </b-dropdown-item>
        </template>

        <!-- Logout -->
        <b-dropdown-item
          link-class="d-flex align-items-center bg-light-info"
          @click="logOut()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("user-menu.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <teleport to="body">
        <AppCustomizer
          :visible="settingsMenu"
          @change="handleCustomizerChange"
        />
      </teleport>

      <!-- Group Chat -->
      <teleport to="body">
        <ChatSidebar
          v-if="isChatRoomVisible"
          #default="{ hide }"
          v-model="isChatRoomVisible"
          @shown="$refs.chatRoom.scrollToBottom()"
        >
          <BasicCollectiveChatRoomWidget
            ref="chatRoom"
            stretch
            autofocus
            :title="translate(collective.name) || collective.title"
            show-avatar
            sidebar
            class="mb-0 rounded-0"
          >
            <template #header>
              <b-button-close @click="hide">
                <b-icon-x />
              </b-button-close>
            </template>
          </BasicCollectiveChatRoomWidget>
        </ChatSidebar>
      </teleport>
    </b-navbar-nav>

    <!-- Contact / Feedback / suggestions -->
    <b-modal
      id="feedback-modal"
      :title="$t('backoffice.feedback.modal-title')"
      :ok-title="$t('form.actions.send')"
      size="lg"
      ok-only
      @hidden="isValid = null"
      @ok="handleSuggestion()"
    >
      <b-form-group class="flex-grow-1">
        <b-form-group class="flex-grow-1">
          <label for="suggestionType" class="font-weight-bold">
            {{ $t("backoffice.feedback.type") }}</label
          >
          <v-select
            v-model="suggestion.type"
            name="suggestionType"
            label="suggestionType"
            :searchable="false"
            :clearable="false"
            :options="typeOptions"
          />
        </b-form-group>
        <label for="suggestion" class="font-weight-bold mt-1">
          {{ $t("backoffice.feedback.name") }}*</label
        >
        <b-form-textarea
          id="suggestion"
          v-model="suggestion.message"
          type="text"
          rows="6"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </b-modal>

  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import Teleport from "vue2-teleport";
import {
  ENABLED_APPS_GETTERS,
  ENABLED_APPS_MUTATIONS,
} from "@/store/enabled-apps/enabled-apps-store-constants";
import { NOTIFICATIONS_GETTERS, NOTIFICATIONS_MUTATIONS } from "@/store/notifications/notifications-store-constants";
import GridStatusMixin from "@core/mixins/apps-layout/GridStatusMixin";
import { toAbsoluteURL } from "@core/utils/url-utils";
import Vue from "vue";
import Service from "@/config/service-identifiers";
import vSelect from "vue-select";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import NectiosLogo from "@/assets/images/placeholders/light/Nectios_Logo.jpg";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import NectiosIcon from "@/assets/images/placeholders/light/Nectios-icon.svg";
import { MainTypeTranslated } from "@copernicsw/community-common";
import { isDownSize } from "@/@core/utils/responsive-utils";
import { getCollectiveUrl } from "@core/utils/collective";
import {
  menuItems,
} from "@/backoffice/constants/backofficeMenu";
import { myselfMenuItems } from "@/views/myself/components/myMenu";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import BasicCollectiveChatRoomWidget from "@/views/apps/chat/widgets/BasicCollectiveChatRoomWidget.vue";
import UserAvatar from "@core/components/user/UserAvatar.vue";
import ChatSidebar from "@/views/apps/chat/components/ChatSidebar.vue";
import NotificationsLink from "./NotificationsLink.vue";
import DeleteUserModal from "./navbar-modals/deleteUser.vue";
import AboutNectiosModal from "./navbar-modals/aboutNectios.vue";

export default {
  name: "NexosNavbar",
  directives: {
    "b-tooltip": VBTooltip,
  },

  components: {
    Teleport,
    vSelect,
    AppCustomizer,
    BasicCollectiveChatRoomWidget,
    UserAvatar,
    ChatSidebar,
    NotificationsLink,
    DeleteUserModal,
    AboutNectiosModal
  },

  mixins: [GridStatusMixin, ToastNotificationsMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isChatRoomVisible: false,
      isOnlyStaff: false,
      isOpenedStaffModal: false,
      isOpenedSoftwareModal: false,
      isOpenedDetailsModal: false,
      isMainCollectiveMember: false,
      isLeaveModalOpened: false,
      organizationsTeleport: false,
      areOrganizationsLoading: false,
      member: {},
      isFirstConnection: false, // Not implemented in BE
      stage: null,
      enabledOrganizationApp: false,
      detailsCommunity: [],
      activatedApps: [],
      suggestion: {
        message: "",
        type: "",
      },
      isSmallDevice: true,
      isMediumDevice: false,
      isLargeDevice: false,
      notificationsCount: null,
      notificationsList: null,
      localUnreadCount: 0,
    };
  },
  computed: {
    hasMenu() {
      return (
        (this.contentLayoutType === "vertical" || this.isMobile) &&
        (this.activatedApps.length > 0 ||
          this.hasBackofficeMenu ||
          this.hasMyselfMenu ||
          this.hasStructure)
      );
    },
    locale() {
      return this.$store.state.locale;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    locales() {
      return this.$store.getters.locales;
    },
    activeLocale() {
      const lang = this.locales.filter((language) => this.currentLocale === language.value);
      // console.log('lang', lang);
      return lang? lang[0] : null;
    },
    isMobile() {
      return window.innerWidth < 1000;
    },
    mySubscription() {
      const nectios = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        "app"
      )?.replace("{slug}", "my");
      return `${nectios}/subscription`;
    },
    placeholder() {
      return Placeholder;
    },
    contentLayoutType() {
      if (this.isMobile) {
        return "vertical";
      }
      if (
        this.$route.path.includes("/backoffice/") ||
        this.$route.path.includes("/myself/")
      ) {
        return "vertical";
      }
      if (this.$store.getters.currentCollective.menu?.layout) {
        return this.$store.getters.currentCollective.menu.layout;
      }
      return this.$store.state.appConfig.layout.type;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMember() {
      const loggedMemberObject = this.$store.getters.loggedMember;
      if(!loggedMemberObject.username){
        return null;
      }
      return loggedMemberObject;
    },
    profileFullName() {
      return this.loggedMember 
      ? (this.loggedMember.name + ' ' + this.loggedMember.surname) 
      : (this.loggedUser.name + ' ' + this.loggedUser.surname) ;
    },
    profileName() {
      return this.loggedMember ? this.loggedMember.name : this.loggedUser.name;
    },
    profileSurname() {
      return this.loggedMember ? this.loggedMember.surname : this.loggedUser.surname;
    },
    profileEmail() {
      return this.loggedMember ? this.loggedMember.email : this.loggedUser.email;
    },
    userOrMember() {
      if(this.loggedMember){
        return this.loggedMember;
      }
      return this.loggedUser;
    },
    isViewerMember() {
      return this.currentCollective.viewerStatus === 3;
    },
    isCommunityMember() {
      return this.loggedMember &&
        this.loggedMember.username &&
        this.loggedMember.memberOfThis &&
        this.loggedMember.status === 3 &&
        this.isViewerMember;
    },
    isViewerMemberOrStaff() {
      return this.isViewerMember || this.isStaff;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    subcollective() {
      return this.$store.state.collective.subcollective;
    },
    collectiveName() {
      return this.$store.state.collective.collective.slug;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCommunity() {
      return this.mainCollective == null
        ? this.currentCollective || this.$route.params.communitySlug
        : this.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    hasMainCommunity() {
      return (
        Object.entries(this.mainCommunity).length !== 0 &&
        this.currentCollective !== this.mainCommunity
      );
    },
    settingsMenu() {
      return this.$store.state.uiStates.settingsMenu;
    },
    isHome() {
      return this.$route.name === "Resumen";
    },
    apps(){
      return this.$store.getters.apps?.apps
    },
    isStaff() {
      return this.currentCollective.isStaff || this.mainCollective?.isStaff;
    },
    isStaffOfThis() {
      return (
        this.currentCollective.isStaffOfThis || this.currentCollective.isStaff
      );
    },
    memberAccountRoute() {
      return { 
        name: 'memberProfile', 
        params: { 
          communityId: this.mainSpace.slug
        }
      };
    },
    canEditLayout() {
      return (
        this.isHome && this.currentCollective.isStaff && !this.isSmallDevice
      );
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    unreadNotificationsCount() {
      // return this.$store.getters[NOTIFICATIONS_GETTERS.unreadCount];
      return this.localUnreadCount;
    },
    typeOptions() {
      return [
        this.$t("backoffice.feedback.type-options.bug"),
        this.$t("backoffice.feedback.type-options.improvement"),
        this.$t("backoffice.feedback.type-options.query"),
      ];
    },
    hasNotMenu() {
      return (
        this.$route.name === "onboarding-form" ||
        this.$route.name === "onboarding-plans" ||
        this.$route.name === "onboarding-checkout" ||
        this.$route.name === "onboarding-success"
      );
    },
    nectiosLogo() {
      return NectiosLogo;
    },
    isCommunity() {
      // This information will not come out if we are in my-netios
      return (
        this.$route?.params?.communityId || this.$route?.params?.communitySlug
      );
    },
    hasLayout() {
      const response = this.$store.getters.apps.apps;
      return response == null ? 0 : Object.keys(response).length;
    },
    truncatedLink() {
      if (this.isSmallDevice) {
        if (
          this.detailsCommunity &&
          this.detailsCommunity.landing.length > 23
        ) {
          return `${this.detailsCommunity.landing?.substring(0, 22)}...`;
        }
      } else if (this.isMediumDevice) {
        if (
          this.detailsCommunity &&
          this.detailsCommunity.landing.length > 34
        ) {
          return `${this.detailsCommunity.landing?.substring(0, 33)}...`;
        }
      }
      return this.detailsCommunity.landing;
    },
    hasBackofficeMenu() {
      return (
        menuItems.includes(this.$route.name) || this.$route.name === "records"
      );
    },
    hasMyselfMenu() {
      return myselfMenuItems.includes(this.$route.name);
    },
    hasStructure() {
      return (
        this.collective.communityMain &&
        this.collective.parentKey &&
        this.collective.communityMain !== this.collective.parentKey
      );
    },
    isAppSumo() {
      return this.$store.getters.loggedUser?.appSumoID;
    },
    spaceSlug() {
      return process.env.VUE_APP_MAIN_SPACE_SLUG || "nexos";
    }
  },
  watch: {
    mainCollective(value) {
      //this.installedMainApps();
      return value;
    },
    isMobile(value) {
      return value;
    },
  },
  mounted(){
    this.$store.dispatch(NOTIFICATIONS_MUTATIONS.resetNotifications);
    this.fetchNotifications()
  },
  async created() {
    await this.getMainCommunityMember();
    if (this.$route?.params?.communityId) {
      this.fetchData();
      
    }
    this.suggestion.type = this.$t(
      "backoffice.feedback.type-options.improvement"
    );
    //await this.installedMainApps(); //??
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async fetchNotifications(){
      try {
        const response = await this.$store.$service[Service.BackendClient].get('/notifications', {
          params: {
            mainSpaceKey: this.mainSpace.key,
            communityKey: this.currentCollective.key,
            isDescendentOrdered: true,
            page:1,
            count: 8,
          },
        });
        const {data} = response?.data;
        if (data?.length > 0) {
          const sortedNotificationWrtIsRead = data.filter(({ isRead }) => isRead === 0);
          setTimeout(() => {
            this.localUnreadCount = sortedNotificationWrtIsRead.length;
            this.$store.dispatch(NOTIFICATIONS_MUTATIONS.setUnreadCount, sortedNotificationWrtIsRead.length);
            this.$store.dispatch(NOTIFICATIONS_MUTATIONS.SET_NOTIFICATIONS, sortedNotificationWrtIsRead);
          }, 2000);

          this.notificationsList = sortedNotificationWrtIsRead;
        }
        
      } catch(error) {
        console.error('error occurred while getting notifications', error)
      }
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    handleResize() {
      this.isSmallDevice = isDownSize("sm");
      this.isMediumDevice = isDownSize("md");
    },
    async fetchData() {
      const [, communitySlug] = window.location.pathname.split("/");
      const response = this.$store.getters.apps;
      this.activatedApps =
        response.apps != null
          ? Object.values(response.apps).filter((app) => app?.isInMenu)
          : [];
    },
    handleCustomizerChange(visible) {
      if (!visible) {
        this.$store.dispatch("closeSettingsMenu");
      } else {
        this.$store.dispatch("openSettingsMenu");
      }
    },
    openSettings() {
      //console.log('Opening language settings...');
      this.$store.dispatch("openSettingsMenu");
    },
    logOut() {
      // console.log('NexosNavbar.vue - dispatch logout');
      // First dispatch the logout action to clear state and cookies
      this.$store.dispatch("logout").then(() => {
        // Add a small delay to ensure cookies are fully cleared
        setTimeout(() => {
          // Force clear any remaining authentication cookies/storage
          localStorage.removeItem('accessToken');
          sessionStorage.clear();
          
          // For Nexos specifically, redirect to the login page
          const feURL = process.env.VUE_APP_URL;
          const mainSlug = process.env.VUE_APP_MAIN_SPACE_SLUG;
          window.location.href = `${feURL}/${mainSlug}/auth/login`;
        }, 100);
      });
    },
    handleEditLayout() {
      this.$store.commit(ENABLED_APPS_MUTATIONS.toggleEditLayout);
    },
    backofficeURL(item, main = false) {
      if (
        item == null &&
        (this.$route.params.communitySlug == null ||
          this.$route.params.communityId == null)
      ) {
        return "#";
      }
      if (main) {
        item = { slug: item.slug || item.id, subdomain: item?.subdomain };
      } else {
        item = {
          slug:
            this.$route.params.communitySlug || this.$route.params.communityId,
          subdomain: this.currentCollective?.subdomain,
        };
      }
      const mainCommunity = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        item.subdomain || "app"
      )?.replace("{slug}", item.slug);
      return `${mainCommunity}/backoffice/settings`;
    },
    myselfURL() {
      const item = {
        slug:
          this.$route.params.communitySlug || this.$route.params.communityId,
        subdomain: this.currentCollective?.subdomain,
      };
      if (this.isCommunity) {
        const mainCommunity =
          process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
            "{subdomain}",
            item.subdomain || "app"
          )?.replace("{slug}", item.slug);
        return `${mainCommunity}/myself/settings`;
      }
      return "/my/settings";
    },
    myProfileLocation() {
      const item = {
        slug:
          this.$route.params.communitySlug || this.$route.params.communityId,
        subdomain: this.currentCollective?.subdomain,
      };
      const community = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        item.subdomain || "app"
      )?.replace("{slug}", item.slug);
      const username = this.$store.getters.loggedMember?.username;
      return `${community}/members/${username}`;
    },
    async getMainCommunityMember() {
      this.isMainCollectiveMember = this.$store.getters.loggedMember;
    },
    getCollectiveUrl(collective) {
      return getCollectiveUrl(collective, "/welcome");
    },
    async handleSuggestion() {
      if (this.suggestion.message !== "") {
        try {
          const suggestionType =
            this.suggestion.type ===
            this.$t("backoffice.feedback.type-options.bug")
              ? "bug"
              : this.suggestion.type ===
                this.$t("backoffice.feedback.type-options.improvement")
              ? "feature_improvement"
              : "query";
          await this.$store.dispatch("createItem", {
            item: {
              itemType: "backoffice/feedback",
              customName: "feedback",
              requestConfig: {
                message: this.suggestion.message,
                type: suggestionType,
                status: "To do",
              },
            },
          });
          this.notifySuccess(this.$t("backoffice.feedback.messages.success"));
          this.suggestion.message = "";
          this.suggestion.type = this.$t(
            "backoffice.feedback.type-options.improvement"
          );
        } catch {
          this.notifyError(this.$t("backoffice.feedback.messages.error"));
        }
      } else {
        this.notifyError(this.$t("backoffice.feedback.messages.error"));
      }
      // await this.$store.dispatch('getFeedback');
    },
    toAbsoluteURL,
    goToMainRoute() {
      // If we're already on the main route, don't navigate
      if (this.$route.name === 'Resumen') return;
      
      // Navigate to the welcome page of the current community
      // console.log('goToMainRoute - this.spaceSlug', this.spaceSlug);
      this.$router.push({
        name: 'Resumen',
        params: {
          communityId: this.spaceSlug
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.sub-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.etiquetaSTG {
  border: 5px solid #ffffff;
  border-radius: 27px;
  color: white;
  background-color: orange;
  font-weight: bold;
  font-size: 1.1em;
  padding: 5px 10px 5px 10px;
}
.etiquetaLocal {
  border: 5px solid #ffffff;
  border-radius: 27px;
  color: white;
  background-color: purple;
  font-weight: bold;
  font-size: 1.1em;
  padding: 5px 10px 5px 10px;
}
.avatar {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  background: white;
}
.menu {
  display: block;
}
a.disabled {
  pointer-events: none;
}

// .icon-nectios {
//   color: #6e6b7b;
// }
//   .icon-nectios {
//   filter: grayscale(100%);
//   color: $primary !important;
// }

// .icon-text:hover {
//       color: $primary !important;
// }

@media screen {
  @media (max-width: 550px) {
    .etiquetaSTG {
      display: none;
    }
    .etiquetaLocal {
      display: none;
    }
  }
}
@include media-breakpoint-up(lg) {
  .settings {
    min-width: 275px !important;
  }
}
@include media-breakpoint-up(xl) {
  .menu {
    display: none !important;
  }
}

.user-avatar-border {
  border: 2px solid white;
  border-radius: 50%;
}

.logo-nexos {
  margin-left: -0.2rem !important;
  cursor: pointer;
}
</style>
