<template>
  <b-modal
    id="about-nectios-modal"
    :title="$t('about-nectios.title')"
    size="lg"
    hide-footer
  >
    <b-container class="mt-2">
      <h4>{{ $t("about-nectios.what-is") }}</h4>
      <p>{{ $t("about-nectios.content") }}</p>
      <hr />
      <h5>{{ $t("about-nectios.software-version-title") }}</h5>
      <p>1.9.73</p>
      <h5>{{ $t("about-nectios.browser-title") }}</h5>

      <p>{{ $t("about-nectios.browser-content") }}</p>
      <hr />
      <h5>{{ $t("about-nectios.contact-title") }}</h5>
      <b-row>
        <b-col cols="12" lg="6">
          <feather-icon icon="MailIcon" size="16" class="text-body pr-25" />
          <a href="mailto:hello@nectios.com" target="_blank"
            >hello@nectios.com</a
          >
        </b-col>
        <b-col cols="12" lg="6">
          <feather-icon icon="GlobeIcon" size="16" class="text-body pr-25" />
          <a :href="landingURLFrom" target="_blank"> {{ landingURL }} </a>
        </b-col>
      </b-row>
      <hr class="mt-1 mb-50 pb-25" />
      <b-row class="mt-2 mb-50">
        <b-col cols="12" md="6" class="small">
          © {{ $t("brand") }}
          {{ new Date().getFullYear() }}
        </b-col>
        <b-col :class="!isSmallDevice ? 'text-right' : 'text-left'">
          <a :href="privacyNectiosURL" target="_blank" class="mr-50 small">
            {{ $t("header.action.privacy-policy") }}
          </a>
          |
          <a :href="termsNectiosURL" target="_blank" class="ml-50 small">
            {{ $t("about-nectios.terms-conditions") }}
          </a>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { isDownSize } from "@/@core/utils/responsive-utils";

export default {
  data() {
    return {
      isSmallDevice: true,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    landingURLFrom() {
      if (process.env.VUE_APP_LANDING_URL_FROM != null) {
        return process.env.VUE_APP_LANDING_URL_FROM?.replace(
          "{from}",
          "community_navbar"
        )?.replace("{slug}", this.$store.getters.currentCollective.slug);
      }
      return null;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL;
    },
    privacyNectiosURL() {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
        "{subdomain}",
        "app"
      ).replace("{slug}", "my")}/privacy_policy?locale=${this.currentLocale}`;
    },
    termsNectiosURL() {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
        "{subdomain}",
        "app"
      ).replace("{slug}", "my")}/terms_conditions?locale=${this.currentLocale}`;
    },
  },
  created() {
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.isSmallDevice = isDownSize("sm");
    },
  },
};
</script>

<style></style>
